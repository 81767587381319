<template>
    <div class="service-management">
        <div class="service-header">
            <span>{{ $t("linked_service_management.title_service_management_setting") }}</span>
        </div>
        <div class="service-body">
            <div class="service-item" v-for="(item, index) in serviceList">
                <div class="service-item-title">
                    <h2>{{ $t("linked_service_management.label_package_calculation") }}</h2>
                    <span>{{ item.service_name }}</span>
                </div>
                <div class="item-list">
                    <div class="item-details">
                        <span>{{ $t("linked_service_management.title_service_name_to_do_link", { serviceName: item.service_name}) }}</span>
                        <button class="first" @click="openNewTabServiceLink(item.service_url)">{{ $t("linked_service_management.button_external_service_name_to_do_link") }}</button>
                    </div>
                    <div class="item-details second">
                        <span>{{ $t("linked_service_management.label_integrate_carbonix") }}</span>
                        <button class="second" @click="openNewTabRegister(item.id)">{{ $t("linked_service_management.button_integrate_carbonix") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ROUTES } from "@/router/constants";
import { mapActions, mapState } from "vuex";
import { getListServiceManagement } from  '@/api/linked-service-management';

export default {
    data() {
        return {
            items: [
                {
                    text: this.$t('linked_service_management.hyperlink_home_service_management_setting'),
                    disabled: false,
                    href: this.$route.path.includes(ROUTES.PRODUCTS) ? ROUTES.PRODUCTS + ROUTES.HOME_DASHBOARD : ROUTES.HOME_DASHBOARD
                },
                {
                    text: this.$t('linked_service_management.hyperlink_setting_service_management_setting'),
                    disabled: false,
                    href: this.$route.path.includes(ROUTES.PRODUCTS) ? ROUTES.PRODUCTS + ROUTES.SETTING : ROUTES.SETTING
                },
                {
                    text: this.$t('linked_service_management.hyperlink_text_service_management_setting'),
                    disabled: true,
                    href: ROUTES.PRODUCTS + ROUTES.LINKED_SERVICE_MANAGEMENT

                }
            ],
            serviceList: []
        }
    },
    computed: {
    },
    methods: {
        ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoading"]),
        
        openNewTabRegister(serviceId) {
            const routerNav = this.$route.path.includes(ROUTES.PRODUCTS) ? ROUTES.PRODUCTS + ROUTES.LINKED_SERVICE_MANAGEMENT_REGISTER : ROUTES.LINKED_SERVICE_MANAGEMENT_REGISTER
            this.$router.push({ path: `${routerNav}?service_id=${serviceId}`});
        },
        openNewTabServiceLink(text) {
            const textLink = text ?? 'https://solution.toppan.co.jp/packaging/service/smartlca.html';
            window.open(textLink, '_blank');
        }
    },
    async mounted() {
        this.updateBreadCrumb(this.items);
        const serviceList = await getListServiceManagement();
        this.serviceList = serviceList.data;
    }
}
</script>
<style lang="scss" scoped>
.service-management {
    .service-header {
        padding: 40px 0px 80px 0px;

        span {
            color: $goldMid;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            /* 163.636% */
            letter-spacing: 1.1px;
        }
    }

    .service-body {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .service-item {
            margin: 0px 20px;
            display: flex;
            padding: 16px;
            flex-direction: column;
            gap: 8px;
            flex-shrink: 0;
            border-radius: 4px;
            background: $monoWhite;

            .service-item-title {
                h2 {
                    color: $monoBlack;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    /* 186.667% */
                    letter-spacing: 0.75px;
                }

                span {
                    color: $monoBlack;

                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                    /* 186.667% */
                    letter-spacing: 0.75px;
                }
            }

            .item-list {
                display: flex;
                padding: 8px 16px;
                flex-direction: column;
                justify-content: center;
                gap: 16px;

                .item-details {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: start;
                    justify-content: space-between;

                    span {
                        color: $monoBlack;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        letter-spacing: 0.6px;
                    }

                    button {
                        display: flex;
                        padding: 7px 16px 9px 16px;
                        text-align: center;
                        border-radius: 4px;
                        background: $monoOffWhite;
                        box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
                        color: $monoBlack;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                        /* 183.333% */
                        letter-spacing: 0.6px;
                        &:hover {
                            background-color: #a4a184;
                            color: $monoWhite;
                        }

                        &.first {
                            text-align: center;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 22px;
                            /* 183.333% */
                            letter-spacing: 0.6px;
                        }

                        &.second {
                            text-align: center;

                            /* Labels/Medium */
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                            /* 171.429% */
                            letter-spacing: 0.42px;
                        }
                    }
                }
            }

        }
    }
}

@include desktop {
    .service-management {
        .service-header {
            padding: 40px 40px 80px 40px;
        }
        .service-body {
            width: 540px;

            .service-item {
                margin: unset;
                .item-list {
                    .item-details {
                        flex-direction: row;
                        gap: auto;
                        align-items: center;
                    }
                }
            }
        }
    }
}
</style>